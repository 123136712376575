const locale = {
	TITLES: 'quotes to be validated',
	TITLE: 'quote to be validated',
	TITLE_SHORT: 'Quote',
	I_ACCEPTED: 'I accept',
	I_DECLINED: 'I refuse',
	CHANGE_MIND: 'I changed my mind',
	SEARCH: 'Search',
	CREATED: 'Created',
	ASSETS: 'asset',
	WEIGHT: 'Weight',
	WEIGHT_BY_UNITS: 'Weight by unit',
	TOTAL_WEIGHT: 'Total weight',
	WEIGHT_KG: 'Weight* (kg)',
	DESTINATION: 'destination',
	CO2: 'CO2 emitted',
	QUOTE: 'Quote',
	UNREAD_FILTER: 'Unread',
	READ_FILTER: 'Read',
	SHOW_FILTERS: 'Filters',
	STATUS_FILTER: 'Status',
	RANGE_BY: 'Order by',
	RANGE_BY_ASC: 'From latest to oldest',
	RANGE_BY_DSC: 'From oldest to latest',
	HIDE_FILTERS: 'Hide filters',
	CLOSE: 'Close',
	NEW_COTATION: 'New Quote',
	COTATION_ELEMENTS: 'Quote elements',
	VALIDATION_COTATION: 'Quote Validation',
	VALID_COTATION: 'Quote Validation',
	MORE_INFOS: 'More informations',
	ROUTE: 'Route',
	ADD_FILES_QUOTE_INVOICE: 'Add Quotation/Invoicing documents',
	LINK_FILES: 'Links to documents',
	ADD_FILES_COTATION_TEXT_1: 'Texte explicatif de cette partie',
	VALID_COTATION_TEXT_1: 'Texte explicatif de cette partie',
	NEW_COTATION_TEXT_1: 'Texte explicatif de cette partie',
	NEW_COTATION_TEXT_2: 'Texte explicatif de cette partie',
	NEW_COTATION_TEXT_3: 'Texte explicatif de cette partie',
	COTATION_REFERENCE: 'Quote reference',
	PICK_UP_INFORMATION: 'Pick-up informations*',
	DELIVERY_INFORMATION: 'Delivery informations*',
	INCOTERM: 'Incoterm',
	FASHION_HUB: 'Fashion Hub',
	SELECT_FASHION_HUB: 'Select Fashion Hub',
	PACKING_LIST: 'Packing list',
	ADD_ASSET: 'Add an asset',
	NEXT: 'Next',
	PREVIOUS: 'Previous',
	VALIDATE: 'Validate',
	ASSET: 'Item',
	DIMENSION: 'Dimensions* (cm)',
	LENGTH: 'Leng.',
	WIDTH: 'Widt.',
	DEPTH: 'Dept.',
	CONTENT: 'Content',
	DETAILS: 'Transported products, HS Code...',
	CHARACTERISTIC: 'Characteristic',
	SERVICE_DETAILS: 'Service details',
	SPECIAL_HANDLING_REQUEST: 'Special handling request',
	DGR: 'Dangerous goods',
	TAIL_LIFT_TO_LOAD: 'Tail lift to load',
	AD_VALOREM: 'Ad valorem insurance',
	AD_VALOREM_TEXT: 'Enter the value of your package',
	GOOD_VALUE: 'Good value (€)',
	RETURN_TRIP: 'Return trip',
	COLD_CHAIN: 'Cold Chain +2°C/+8°C',
	WRITE_COMMENT: 'Write comment',
	ROUTE_OPTIONS: 'Route options*',
	ACCESS: 'Accessible',
	GREEN: 'Green',
	CRITICAL: 'Critical',
	PREMIUM: 'Premium',
	ACCESS_TEXT: 'Economic',
	GREEN_TEXT: 'Low CO2 emission',
	CRITICAL_TEXT: 'The fastest',
	PREMIUM_TEXT: 'The best delivery experience',
	REMOVAL_CONTACT: 'Pickup contact',
	DELIVERY_CONTACT: 'Delivery contact',
	NAME: 'Name',
	EMAIL: 'Email address',
	PHONE: 'Phone',
	SURNAME: 'Surname',
	QUOTE_ELEMENTS: 'Quote elements',
	INVOICE_ELEMENTS: 'Invoice elements',
	FAVORITE_TRANSPORT_TYPE: 'Transport type*',
	SELECT_INCOTERM: 'Select Incoterm',
	QUOTE_PRICE: 'Qutoe price (in EUR)',
	INVOICE_PRICE: 'Invoice price (en EUR)',
	CO2_EMISSION: 'CO2 Emission (in ton)',
	PLANE: 'Plane',
	BOAT: 'Boat',
	CAR: 'Road',
	TRAIN: 'Train',
	MULTI: 'Multimodal',
	INTERN_REFERENCE: 'Intern reference',
	WRITE_REFERENCE: 'Write your reference',
	UPLOAD_INVOICE: 'Upload Invoice',
	UPLOAD_QUOTE: 'Upload Quote',
	QUANTITY: 'Quantity',
	COMMENT: 'Comment',
	DEPARTURE: 'Departure',
	DESTINATION_END: 'Arrival',
	BOUTON_CANCEL: 'Cancel',
	BOUTON_FINISHED: 'Validate',
	SERVICE: 'Services',
	CONTACT: 'Contacts',
	FILES: 'Files',
	EQUIVALENT_EMISSION: 'CO2 equivalent emissions',
	CONDITIONS: 'Conditions',
	CHARGEABLE_WEIGHT: 'Chargeable Weight',
	GROSS_WEIGHT: 'Gross Weight',
	ADD_FILE: 'Add file',
	ADD_FILE_TEXT: '(Commercial invoice, Packing List, MSDS, DGD...)',
	UPLOAD_FROM_COMPUTER: 'Upload from your computer',
	INVOICE: 'Invoice',
	TRANSPORT: 'Transport',
	PICK_UP: 'Pick up',
	UPDATE_CATEGORY: 'Updated',
	DELIVERY: 'Delivery',
	REPORT: 'Report',
	BILING: 'Biling',
	OTHER: 'Other',
	AGENT: 'Agent',
	RESUME_COTATION: 'Quote Resume',
	TEXT_UPLOAD_FILE: 'Uploaded file : ',
	DOWNLOAD_QUOTE: 'Download quote',
	DECLINE_COTATION: 'Decline quote',
	DECLINE_COTATION_TEXT: 'Do you confirm the refusal of the quotation?',
	DECLINE: 'Decline',
	CANCEL: 'Cancel',
	DUPLICATE_COTATION: 'Duplicate quote',
	DELETE_COTATION: 'Delete quote',
	SUBMITTED: 'Submitted',
	ACCEPTED: 'Accepted',
	DECLINED: 'Declined',
	DIRECT_BOOKING: 'Direct Booking',
	BOOK: 'Direct Booking',
	BOOKING_CONFIRMATION: 'Are you sure you want to book ?',
	BOOKING_CONFIRMATION_TEXT: 'Book your transport without establishing a quote in advance',
	TAG: 'Tag',
	FILTER: 'Filters',
	CO2_FACTOR: `CO2 emission factor (in ton)`,
	ALL_IN_WO_TAX: `Price excluding tax (in  €)`,
	CHATBOX: 'Chatbox',
	ACCEPT_QUOTE: 'Accept quote',
	DECLINE_QUOTE: 'Decline quote',
	SEND_QUOTE: 'Send quote',
	ASK_QUOTE: 'Ask for quote',
	UPDATE: 'Update',
	DELIVERY_DATE: 'Desired delivery',
	OPTIONAL: 'optional',
	PICKUP_DATE: 'Merchandise availability : ',
	LOADING_MSG: 'Wait a moment...',
	WEIGHT_TYPE: 'Units of measure',
	CONTAINER_TYPE: 'Type of container :',
	TYPE: 'Type :',
	PALLET: 'Pallet',
	CUSTOM_PALLET: 'Custom pallet',
	CUSTOM: 'Custom',
	PARCEL: 'Parcel',
	SOCIETY: 'Society',
	OPENING_TIME: 'Opening time',
	WEIGHT_CALCULATION: 'Weight calculation',
	ERROR_PACKING_LIST: '(Packing List) | Please fill all inputs',
	DESCRIPTION: 'Description',
	CONTRACTOR: 'Contractor',
	CLIENT: 'Client',
	ORDER_BY: 'Order by',
	FILTER_BY: 'Filter by',
	LOADING: 'Loading...',
	ENDSCROLL: 'No more cotations',
	ADDRESS_BOOK: 'Phone book',
	SAVING: 'Save contact',
	NO_MAIL: 'No e-mail address',
	INPUT_MISSING: 'Phone, name and surname are mandatory',
	TRANSPORT_RETURN: 'Book a return'
};

export default locale;
