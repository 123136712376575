const locale = {
	TITLES: 'cotations à valider',
	TITLE: 'cotation à valider',
	TITLE_SHORT: 'Cotations',
	I_ACCEPTED: "J'accepte",
	I_DECLINED: 'Je refuse',
	CHANGE_MIND: "Je change d'avis",
	SEARCH: 'Rechercher',
	CREATED: 'créée',
	ASSETS: 'colis',
	WEIGHT: 'Poids',
	WEIGHT_BY_UNITS: 'Poids par unité',
	TOTAL_WEIGHT: 'Poids total',
	WEIGHT_KG: 'Poids* (kg)',
	DESTINATION: 'destination',
	CO2: 'CO2 émis',
	UNREAD_FILTER: 'Non lus',
	READ_FILTER: 'Lus',
	SHOW_FILTERS: 'Filtrer',
	STATUS_FILTER: 'Statut',
	RANGE_BY: 'Ranger par',
	RANGE_BY_ASC: 'Du plus récent au moins récent',
	RANGE_BY_DSC: 'Du moins récent au plus récent',
	HIDE_FILTERS: 'Masquer les filtres',
	CLOSE: 'Annuler',
	NEW_COTATION: 'Nouvelle Cotation',
	COTATION_ELEMENTS: 'Éléments de cotation',
	VALIDATION_COTATION: 'Validation Cotation',
	VALID_COTATION: 'Validation Cotation',
	MORE_INFOS: 'Informations complémentaires',
	ROUTE: 'Parcours',
	ADD_FILES_QUOTE_INVOICE: 'Ajout documents Devis/Facturation',
	LINK_FILES: 'Liens vers documents',
	ADD_FILES_COTATION_TEXT_1: 'Texte explicatif de cette partie',
	VALID_COTATION_TEXT_1: 'Texte explicatif de cette partie',
	NEW_COTATION_TEXT_1: 'Texte explicatif de cette partie',
	NEW_COTATION_TEXT_2: 'Texte explicatif de cette partie',
	NEW_COTATION_TEXT_3: 'Texte explicatif de cette partie',
	COTATION_REFERENCE: 'Référence de Cotation',
	PICK_UP_INFORMATION: "Informations d'enlèvement*",
	DELIVERY_INFORMATION: 'Informations de livraison*',
	INCOTERM: 'Incoterm',
	ADD_ASSET: 'Ajouter un colis',
	NEXT: 'Suivant',
	PREVIOUS: 'Précédent',
	VALIDATE: 'Valider',
	ASSET: 'Colis',
	DIMENSION: 'Dimensions* ',
	LENGTH: 'Long.',
	WIDTH: 'Larg.',
	DEPTH: 'Haut.',
	CONTENT: 'Contenu',
	DETAILS: 'Produits transportés, HS Code...',
	CHARACTERISTIC: 'Caractéristique',
	SERVICE_DETAILS: 'Détails du service',
	SPECIAL_HANDLING_REQUEST: 'Requêtes spéciales',
	TAIL_LIFT_TO_LOAD: 'Hayon à charger',
	AD_VALOREM: 'Assurance Ad valorem',
	AD_VALOREM_TEXT: 'Rentrez la valeur de votre colis',
	GOOD_VALUE: 'Valeur colis (€)',
	RETURN_TRIP: 'Voyage retour',
	COLD_CHAIN: 'Chaine du froid +2°C/+8°C',
	ROUTE_OPTIONS: 'Options du parcours*',
	ACCESS: 'Economique',
	GREEN: 'Ecoresponsable',
	CRITICAL: 'Urgent',
	PREMIUM: 'Premium',
	ACCESS_TEXT: 'Économique',
	GREEN_TEXT: 'Basse émission de CO2',
	CRITICAL_TEXT: 'Le plus rapide',
	PREMIUM_TEXT: 'La meilleure expérience de livraison',
	REMOVAL_CONTACT: "Contact à l'enlèvement",
	DELIVERY_CONTACT: 'Contact à la livraison',
	NAME: 'Nom',
	WRITE_COMMENT: 'Écrire commentaire',
	EMAIL: 'Adresse email',
	PHONE: 'Téléphone',
	SURNAME: 'Prénom',
	QUOTE_ELEMENTS: 'Elements de devis',
	INVOICE_ELEMENTS: 'Elements de facturation',
	FAVORITE_TRANSPORT_TYPE: 'Type de transport*',
	SELECT_INCOTERM: 'Sélectionner Incoterm',
	FASHION_HUB: 'Fashion Hub',
	SELECT_FASHION_HUB: 'Sélectionner Fashion Hub',
	QUOTE_PRICE: 'Prix du devis (en EUR)',
	INVOICE_PRICE: 'Prix facture (en EUR)',
	CO2_EMISSION: 'Emission CO2 (en tonnes)',
	PLANE: 'Aérien',
	BOAT: 'Maritime',
	CAR: 'Routier',
	TRAIN: 'Ferroviaire',
	MULTI: 'Multimodal',
	INTERN_REFERENCE: 'Référence interne',
	WRITE_REFERENCE: 'Écrire votre référence',
	UPLOAD_INVOICE: 'Télécharger une facture',
	UPLOAD_QUOTE: 'Télécharger un devis',
	QUANTITY: 'Quantité',
	COMMENT: 'Commentaire',
	DEPARTURE: 'Départ',
	DESTINATION_END: 'Arrivée',
	BOUTON_CANCEL: 'Annuler',
	BOUTON_FINISHED: 'Valider',
	SERVICE: 'Service',
	CONTACT: 'Contacts',
	FILES: 'Documents',
	EQUIVALENT_EMISSION: 'Emissions équivalent CO2',
	CONDITIONS: 'Conditions',
	CHARGEABLE_WEIGHT: 'Chargeable Weight',
	GROSS_WEIGHT: 'Gross Weight',
	ADD_FILE: 'Ajouter document',
	ADD_FILE_TEXT: '(Facture commerciale, Packing List, MSDS, DGD...)',
	UPLOAD_FROM_COMPUTER: 'Charger depuis votre ordinateur',
	QUOTE: 'Cotation',
	PACKING_LIST: 'Liste de colisage',
	INVOICE: 'Facture',
	DGR: 'DGR',
	TRANSPORT: 'Transport',
	PICK_UP: 'Collecte',
	UPDATE_CATEGORY: 'Mis a Jour',
	DELIVERY: 'Livraison',
	REPORT: 'Rapport',
	BILING: 'Facturation',
	OTHER: 'Autre',
	AGENT: 'Agent',
	RESUME_COTATION: 'Résumé de Cotation',
	TEXT_UPLOAD_FILE: 'Fichier envoyé : ',
	DOWNLOAD_QUOTE: 'Télécharger Devis',
	DECLINE_COTATION: 'Refuser la cotation',
	DECLINE_COTATION_TEXT: 'Confirmez vous le refus de la cotation ?',
	DECLINE: 'Refuser',
	CANCEL: 'Annuler',
	DUPLICATE_COTATION: 'Dupliquer Cotation',
	DELETE_COTATION: 'Supprimer Cotation',
	SUBMITTED: 'En attente',
	ACCEPTED: 'Accepté',
	DECLINED: 'Refusé',
	DIRECT_BOOKING: 'Réserver',
	TAG: 'Tag',
	FILTER: 'Filtrer',
	CO2_FACTOR: `Facteur d'émission (en tonnes)`,
	ALL_IN_WO_TAX: `Tarif hors taxe (en €)`,
	CHATBOX: 'Messagerie',
	ACCEPT_QUOTE: 'Valider le devis',
	DECLINE_QUOTE: 'Refuser le devis',
	SEND_QUOTE: 'Envoyer le devis',
	ASK_QUOTE: 'Obtenir une cotation',
	UPDATE: 'Mettre à jour',
	BOOK: 'Réserver',
	BOOKING_CONFIRMATION: 'Êtes-vous sûr de vouloir réserver ?',
	BOOKING_CONFIRMATION_TEXT: 'Réserver votre transport sans établir de devis au préalable',
	REFRESH: 'Rafraichir',
	EDIT: 'Modifier la référence',
	DELIVERY_DATE: 'Livraison souhaitée',
	OPTIONAL: 'facultatif',
	PICKUP_DATE: 'Disponibilité marchandise : ',
	LOADING_MSG: 'Veuillez patienter...',
	WEIGHT_TYPE: 'Unités de mesure',
	CONTAINER_TYPE: 'Type de conteneur :',
	TYPE: 'Type :',
	PALLET: 'Palette',
	CUSTOM_PALLET: 'Palette personnalisée',
	CUSTOM: 'Personalisée',
	PARCEL: 'Colis',
	SOCIETY: 'Société',
	OPENING_TIME: "Horaires d'ouverture",
	WEIGHT_CALCULATION: 'Calcul du poids',
	EXPORT_DATA: 'Export Excel',
	ERROR_PACKING_LIST: '(Liste de Colisage) | Veuillez remplir tous les champs ',
	DESCRIPTION: 'Description',
	CONTRACTOR: "Donneur d'ordre",
	CLIENT: 'Client',
	ORDER_BY: 'Trier par',
	FILTER_BY: 'Filtrer par',
	COTATION: 'Cotation',
	LOADING: 'Chargement...',
	ENDSCROLL: 'Plus de cotations',
	ADDRESS_BOOK: 'Annuaire',
	SAVING: 'Sauvegarder contact',
	NO_MAIL: "Pas d'adresse mail",
	INPUT_MISSING: 'Téléphone, nom et prénom obligatoires',
	TRANSPORT_RETURN: 'Réservé un retour'
};

export default locale;
